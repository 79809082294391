import { QuoteResponsePetInfo } from 'apiHelpers/quote/quoteResponse';
import { isPet9YearsOrOlderOnDate } from 'businessLogic/petAge';
import { graphql, useStaticQuery } from 'gatsby';
import { trimEnd } from 'lodash';
import { Detail } from 'components/CheckYourDetails/DetailsSection/DetailGroupSection';
import { createDetail } from 'components/CheckYourDetails/DetailsSection/useCreateDetail';
import { petNameReplacer } from 'helpers/placeholders/dynamicPetNameHelpers';
import { petPlaceholders } from 'helpers/placeholders/petPlaceholders';
import { confirmationQuotePlaceholders } from 'helpers/placeholders/quotePlaceholders';
import {
  noOpPlaceholderReplacer,
  replacePlaceholdersPlainText,
  replacePlaceholdersRichText,
} from 'helpers/placeholders/replaceCsPlaceholders';
import { getProductFromQuoteOptions, Product } from 'helpers/productHelpers';
import { ConfirmationQuote } from 'state/quote/confirmationQuote';

type QuoteDetailsSectionCsProps = {
  csPetConfirmationQuoteDetailsV2: {
    quote_details: {
      cover_start_date: {
        label: string;
        value: string;
      };
      monthly_payment_amount: {
        label: string;
        value: string;
        explanation: string;
      };
      monthly_payment_day: {
        label: string;
        value: string;
      };
      annual_payment_amount: {
        label: string;
        value: string;
        explanation: string;
      };
      vet_fees_limit: {
        label: string;
        value: {
          essential: string;
          standard: string;
          plus: string;
          premier: string;
        };
      };
      excess: {
        under_9_label: string;
        over_9_label: string;
        under_9_explanation: {
          essential: string;
          standard_plus_premier: string;
        };
        over_9_explanation: {
          essential: string;
          standard_plus_premier: string;
        };
        over_9_value: string;
        under_9_value: string;
      };
    };
  };
};

export const query = graphql`
  query {
    csPetConfirmationQuoteDetailsV2 {
      quote_details {
        cover_start_date {
          label
          value
        }
        monthly_payment_amount {
          label
          value
          explanation
        }
        monthly_payment_day {
          label
          value
        }
        annual_payment_amount {
          label
          value
          explanation
        }
        vet_fees_limit {
          label
          value {
            essential
            standard
            plus
            premier
          }
        }
        excess {
          under_9_label
          over_9_label
          under_9_explanation {
            essential
            standard_plus_premier
          }
          over_9_explanation {
            essential
            standard_plus_premier
          }
          under_9_value
          over_9_value
        }
      }
    }
  }
`;

export const useConfirmationDetails = (
  quote: ConfirmationQuote,
  isAnnualPayment: boolean
): Detail[] => {
  const {
    csPetConfirmationQuoteDetailsV2: { quote_details },
  } = useStaticQuery<QuoteDetailsSectionCsProps>(query);

  const getConfirmationDetailsVetFeesLimit = (product: Product | undefined): string => {
    switch (product) {
      case Product.Essential:
        return quote_details.vet_fees_limit.value.essential;
      case Product.Standard:
        return quote_details.vet_fees_limit.value.standard;
      case Product.Plus:
        return quote_details.vet_fees_limit.value.plus;
      case Product.Premier:
        return quote_details.vet_fees_limit.value.premier;
      default:
        return '';
    }
  };

  const coverStartDate = quote.policyInfo
    ? new Date(quote.policyInfo.coverStartDate)
    : new Date();

  const product = quote?.quoteOptions && getProductFromQuoteOptions(quote.quoteOptions);
  const confirmationDetailsVetFeesLimitCSValue = getConfirmationDetailsVetFeesLimit(
    product
  );

  const richTextQuotePlaceholderReplacer = quote
    ? replacePlaceholdersRichText(confirmationQuotePlaceholders, quote)
    : noOpPlaceholderReplacer;

  const plainTextPlaceholderReplacer = quote
    ? replacePlaceholdersPlainText(confirmationQuotePlaceholders, quote, true)
    : noOpPlaceholderReplacer;

  const createPetDetails = (petInfo: QuoteResponsePetInfo): Detail => {
    const richTextPetPlaceholderReplacer = petInfo
      ? replacePlaceholdersRichText(petPlaceholders, petInfo)
      : noOpPlaceholderReplacer;
    const isEssential = product === Product.Essential;

    if (isPet9YearsOrOlderOnDate(petInfo.dob, coverStartDate)) {
      const excessExplanationText = isEssential
        ? quote_details.excess.over_9_explanation.essential
        : quote_details.excess.over_9_explanation.standard_plus_premier;
      return createDetail(
        petNameReplacer(trimEnd(petInfo.petName), quote_details.excess.over_9_label),
        richTextPetPlaceholderReplacer(quote_details.excess.over_9_value),
        richTextQuotePlaceholderReplacer(excessExplanationText)
      );
    }

    const excessExplanationText = isEssential
      ? quote_details.excess.under_9_explanation.essential
      : quote_details.excess.under_9_explanation.standard_plus_premier;
    return createDetail(
      petNameReplacer(trimEnd(petInfo.petName), quote_details.excess.under_9_label),
      richTextPetPlaceholderReplacer(quote_details.excess.under_9_value),
      richTextQuotePlaceholderReplacer(excessExplanationText)
    );
  };

  return [
    createDetail(
      plainTextPlaceholderReplacer(quote_details.cover_start_date.label),
      richTextQuotePlaceholderReplacer(quote_details.cover_start_date.value)
    ),
    createDetail(
      quote_details.vet_fees_limit.label,
      richTextQuotePlaceholderReplacer(confirmationDetailsVetFeesLimitCSValue)
    ),
  ]
    .concat(quote.petInfos ? quote.petInfos.map(createPetDetails) : [])
    .concat([
      ...(isAnnualPayment
        ? [
            createDetail(
              quote_details.annual_payment_amount.label,
              richTextQuotePlaceholderReplacer(quote_details.annual_payment_amount.value),
              quote_details.annual_payment_amount.explanation
            ),
          ]
        : [
            createDetail(
              quote_details.monthly_payment_amount.label,
              richTextQuotePlaceholderReplacer(
                quote_details.monthly_payment_amount.value
              ),
              quote_details.monthly_payment_amount.explanation
            ),
            createDetail(
              quote_details.monthly_payment_day.label,
              richTextQuotePlaceholderReplacer(quote_details.monthly_payment_day.value)
            ),
          ]),
    ]);
};
