import React from 'react';
import { Detail } from './DetailGroupSection';

export const createDetail = (
  label: string,
  valueOrUndefined: string | undefined,
  explanationText?: string,
  childAfter?: React.ReactNode
): Detail => {
  const value = valueOrUndefined ?? '';
  return {
    label,
    value,
    explanationText,
    childAfter,
  };
};
