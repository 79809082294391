import { getStartOfDay } from '@rsa-digital/evo-shared-components/helpers/dateHelpers';
import { graphql, useStaticQuery } from 'gatsby';
import {
  PlusCoverFeeLimit,
  PremierCoverFeeLimit,
  QuoteOptions,
} from 'state/formData/quoteOptions';
import { useConfirmationQuote } from 'state/quote/confirmationQuote';
import {
  CsAsset,
  CsCoverDurationOfferInformation,
  CsCoverLevelOfferInformation,
  CsQuoteSummaryOfferOverviewPanel,
} from 'types/contentStack';
import { ProductId, ReferrerId } from './businessConstants';
import { processImageAsset } from './csTypeProcessors';
import { getProductFromQuoteOptions, Product } from './productHelpers';
import { CurrentQuote, useCurrentQuote } from './useCurrentQuote';

export enum ActiveProductId {
  Direct = 'Direct',
  GoCompare = 'GoCompare',
  CTM = 'Compare The Market',
  MSM = 'Money Super Market',
  Confused = 'Confused',
}

export enum ActiveCoverDuration {
  TimeLimited = 'Time_Limited',
  Lifetime = 'Lifetime',
}

export enum ActiveLifetimeOption {
  AccidentOrIllnessLimit = 'Accident or Illness Limit',
  TotalAnnualLimit = 'Total Annual Limit',
}

export enum ActiveCoverFeeLimit {
  Plus2k = 'Plus 2000',
  Plus3k = 'Plus 3000',
  Premier5k = 'Premier 5000',
  Premier7k = 'Premier 7000',
  Premier10k = 'Premier 10000',
}

type CsPetOfferPanel = {
  csPetDiscountsV2: {
    quote_summary_offer_overview: CsQuoteSummaryOfferOverviewPanel;
    cover_duration_offer: CsCoverDurationOfferInformation[];
    lifetime_option_offer: CsCoverDurationOfferInformation[];
    plus_premier_offer: CsCoverDurationOfferInformation[];
    quote_summary_offers: CsCoverLevelOfferInformation[];
    confirmation_offers: CsCoverLevelOfferInformation[];
    price_panel_offer_section: CsCoverLevelOfferInformation[];
  };
};

const query = graphql`
  query {
    csPetDiscountsV2 {
      quote_summary_offers {
        icon {
          icon_code
        }
        content_rich_text
        active_routes_to_quote
        active_cover_levels
        offer_start_date
        offer_end_date
      }
      price_panel_offer_section {
        icon {
          icon_code
        }
        content_rich_text
        active_routes_to_quote
        active_cover_levels
        offer_start_date
        offer_end_date
      }
      confirmation_offers {
        icon {
          icon_code
        }
        content_rich_text
        active_routes_to_quote
        active_cover_levels
        offer_start_date
        offer_end_date
      }
    }
  }
`;

const useQuery = (): CsPetOfferPanel => useStaticQuery<CsPetOfferPanel>(query);

const isOfferValidForProductId = (
  productId: ProductId,
  referrerId: string | undefined,
  activeProductIds: ActiveProductId[]
): boolean => {
  if (activeProductIds === null || activeProductIds?.length < 1) {
    return false;
  }

  switch (productId) {
    case ProductId.DIRECT:
      if (referrerId === null || referrerId !== ReferrerId.Affiliate) {
        return activeProductIds.includes(ActiveProductId.Direct);
      }
      return false;
    case ProductId.GO_COMPARE:
      return activeProductIds.includes(ActiveProductId.GoCompare);
    case ProductId.COMPARE_THE_MARKET:
      return activeProductIds.includes(ActiveProductId.CTM);
    case ProductId.MONEY_SUPERMARKET:
      return activeProductIds.includes(ActiveProductId.MSM);
    case ProductId.CONFUSED:
      return activeProductIds.includes(ActiveProductId.Confused);
    default:
      return false;
  }
};

const isCoverLevelActive = (
  productType: Product,
  activeCoverLevels: Product[]
): boolean => {
  if (activeCoverLevels === null || activeCoverLevels?.length < 1) {
    return false;
  }
  switch (productType) {
    case Product.Essential:
    case Product.Standard:
    case Product.Plus:
    case Product.Premier:
      return activeCoverLevels.some((prod) => prod.includes(productType));
    default:
      return false;
  }
};

const isPlusCoverFeeLimitActive = (
  quotePlusCoverFeeLimit: PlusCoverFeeLimit,
  activeCoverFeeLimit: ActiveCoverFeeLimit[]
): boolean => {
  switch (quotePlusCoverFeeLimit) {
    case PlusCoverFeeLimit.Limit_2000:
      return activeCoverFeeLimit.includes(ActiveCoverFeeLimit.Plus2k);
    case PlusCoverFeeLimit.Limit_3000:
      return activeCoverFeeLimit.includes(ActiveCoverFeeLimit.Plus3k);
    default:
      return false;
  }
};

const isPremierCoverFeeLimitActive = (
  quotePremierCoverFeeLimit: PremierCoverFeeLimit,
  activeCoverFeeLimit: ActiveCoverFeeLimit[]
): boolean => {
  switch (quotePremierCoverFeeLimit) {
    case PremierCoverFeeLimit.Limit_5000:
      return activeCoverFeeLimit.includes(ActiveCoverFeeLimit.Premier5k);
    case PremierCoverFeeLimit.Limit_7000:
      return activeCoverFeeLimit.includes(ActiveCoverFeeLimit.Premier7k);
    case PremierCoverFeeLimit.Limit_10000:
      return activeCoverFeeLimit.includes(ActiveCoverFeeLimit.Premier10k);
    default:
      return false;
  }
};

const isCoverFeeLimitActive = (
  activeCoverLevels: Product[],
  quoteOptions: QuoteOptions,
  activeCoverFeeLimit: ActiveCoverFeeLimit[]
): boolean => {
  if (
    activeCoverLevels.some((prod) => prod.includes(Product.Plus)) ||
    activeCoverLevels.some((prod) => prod.includes(Product.Premier))
  ) {
    if (quoteOptions.plusCoverFeeLimit !== undefined) {
      return isPlusCoverFeeLimitActive(
        quoteOptions.plusCoverFeeLimit,
        activeCoverFeeLimit
      );
    }
    if (quoteOptions.premierCoverFeeLimit !== undefined) {
      return isPremierCoverFeeLimitActive(
        quoteOptions.premierCoverFeeLimit,
        activeCoverFeeLimit
      );
    }
    return false;
  }
  return true;
};

export const offerIsInActiveRange = (
  offerStartDate: string | undefined,
  offerEndDate: string | undefined
): boolean => {
  const startOfToday = getStartOfDay(new Date());

  const offerStarted = !offerStartDate || startOfToday >= new Date(offerStartDate);
  const offerNotExpired = !offerEndDate || startOfToday <= new Date(offerEndDate);

  return offerStarted && offerNotExpired;
};

export const useValidQuoteSummaryOffers = ():
  | CsCoverLevelOfferInformation
  | undefined => {
  const {
    csPetDiscountsV2: { quote_summary_offers },
  } = useQuery();
  const quote = useCurrentQuote();

  const offerPanel = quote_summary_offers?.find(
    ({ active_routes_to_quote, active_cover_levels, offer_start_date, offer_end_date }) =>
      isOfferValidForProductId(
        quote.productId,
        quote.policyInfo?.referrerId,
        active_routes_to_quote
      ) &&
      isCoverLevelActive(
        getProductFromQuoteOptions(quote.quoteOptions) as Product,
        active_cover_levels
      ) &&
      isCoverFeeLimitActive(
        active_cover_levels,
        quote.quoteOptions,
        (active_cover_levels as unknown) as ActiveCoverFeeLimit[]
      ) &&
      offerIsInActiveRange(offer_start_date, offer_end_date)
  );
  if (offerPanel) {
    return offerPanel;
  }

  return undefined;
};

export const useValidPricePanelOffers = ():
  | CsCoverLevelOfferInformation[]
  | undefined => {
  const {
    csPetDiscountsV2: { price_panel_offer_section },
  } = useQuery();
  const quote = useCurrentQuote();

  const offers = price_panel_offer_section.filter(
    ({ active_routes_to_quote, active_cover_levels, offer_start_date, offer_end_date }) =>
      isOfferValidForProductId(
        quote.productId,
        quote.policyInfo?.referrerId,
        active_routes_to_quote
      ) &&
      isCoverLevelActive(
        getProductFromQuoteOptions(quote.quoteOptions) as Product,
        active_cover_levels
      ) &&
      offerIsInActiveRange(offer_start_date, offer_end_date)
  );

  if (offers) {
    return offers;
  }

  return undefined;
};

export const useValidConfirmationOffers = ():
  | CsCoverLevelOfferInformation[]
  | undefined => {
  const {
    csPetDiscountsV2: { confirmation_offers },
  } = useQuery();
  const [quote] = useConfirmationQuote();
  if (quote) {
    const offers = confirmation_offers.filter(
      ({
        active_routes_to_quote,
        active_cover_levels,
        offer_start_date,
        offer_end_date,
      }) =>
        isOfferValidForProductId(
          quote.productId,
          quote.policyInfo?.referrerId,
          active_routes_to_quote
        ) &&
        isCoverLevelActive(
          getProductFromQuoteOptions(quote.quoteOptions) as Product,
          active_cover_levels
        ) &&
        offerIsInActiveRange(offer_start_date, offer_end_date)
    );

    if (offers) {
      return offers;
    }
  }

  return undefined;
};

export const displayMultipetDiscount = (quote: CurrentQuote): boolean => {
  const coverType = getProductFromQuoteOptions(quote.quoteOptions);
  if (coverType === undefined) {
    return false;
  }

  return (quote.petInfos?.length || 0) > 1;
};

// This is not currently used, but may be in future - current agreed upon approach is to have it show if an icon is found in CS, see EP-514 for details
export const displayOnlineDiscount = (onlineDiscountIcon: CsAsset | null): boolean => {
  const onlineDiscountLogo = processImageAsset(onlineDiscountIcon);
  return onlineDiscountLogo !== undefined;
};
