import { GridItem } from '@rsa-digital/evo-shared-components/components/Grid';
import RichText from '@rsa-digital/evo-shared-components/components/RichText';
import colors from '@rsa-digital/evo-shared-components/helpers/colors';
import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled from 'styled-components';
import DiscountPanel from 'components/DiscountPanel';
import { DiscountPanelContainer, IconWrapper } from 'components/DiscountPanel/styles';
import RichTextWithModal from 'components/RichTextWithModal';

export const IntroMessage = styled(RichText)`
  margin: 0;

  h2:first-child,
  h3:first-child,
  h4:first-child,
  h5:first-child,
  h6:first-child,
  p:first-child {
    margin-top: 0;
  }
`;

export const QuoteDetails = styled(RichTextWithModal)`
  ${RichText} {
    p {
      font-weight: normal;

      ${fonts.headingXSmall};

      ${mediaQuery.tabletLandscape`
      ${fonts.paragraphLarge}
    `}
    }

    margin-bottom: ${spacing(4)};

    ${mediaQuery.tabletLandscape`
    margin-bottom: ${spacing(5)};
  `}
  }
`;

export const DocumentsDispatchingDetails = styled(RichTextWithModal)`
  ${RichText} {
    p {
      ${mediaQuery.tabletPortrait`
        ${fonts.paragraphLarge}
      `}
    }
  }
`;

export const StyledGridItem = styled(GridItem)`
  margin-top: 0;
  margin-bottom: 0;
`;

export const StyledDiscountPanel = styled(DiscountPanel)`
  ${DiscountPanelContainer} {
    border: 2px solid ${colors.core03};
  }
  padding: 0px calc(100vw - 100%);
  margin: 0px calc(100% - 96vw);
  padding-top: ${spacing(3)};
  padding-bottom: ${spacing(3)};
  margin-top: ${spacing(3)};
  margin-bottom: ${spacing(3)};
  display: block;
  ${IconWrapper} {
    float: left;
  }
  ${mediaQuery.tabletPortrait`
    margin: 0px calc(100% - 98vw);     
    padding-top: ${spacing(3)};
    padding-bottom: ${spacing(3)};
    margin-top: ${spacing(3)};
    margin-bottom: ${spacing(3)};
    display: flex;
  `}

  h3 {
    ${fonts.headingSmall};
  }
  h3 {
    font-weight: 600;
    margin: ${spacing(1)} 0 ${spacing(2)} 0;
  }
  h4 {
    font-weight: 700;
    margin: 0;
  }
  p {
    margin-top: ${spacing(1)};
  }
  p + p {
    margin-top: ${spacing(1)};
  }
`;
