import Link from '@rsa-digital/evo-shared-components/components/Link';
import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import { semiBoldFontWeight } from '@rsa-digital/evo-shared-components/theme/MarksAndSpencer/fonts';
import styled from 'styled-components';

export const IconWrapper = styled.div`
  margin-right: ${spacing(1)};
  padding-top: 3px;
  height: ${spacing(2)};
`;

export const StyledLink = styled(Link)`
  && {
    ${fonts.paragraph}

    ${mediaQuery.tabletPortrait`
      ${fonts.headingXSmall}
    `}

    & {
      font-weight: ${semiBoldFontWeight};
    }
  }

  &:focus,
  &:active,
  &:hover {
    text-decoration: none;
  }

  text-decoration: underline;
  display: flex;

  margin-top: ${spacing(4)};

  ${mediaQuery.tabletLandscape`
    margin-top: ${spacing(3)};
  `}
`;
