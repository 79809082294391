import RichText from '@rsa-digital/evo-shared-components/components/RichText';
import colors from '@rsa-digital/evo-shared-components/helpers/colors';
import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import { regularFontWeight } from '@rsa-digital/evo-shared-components/theme/MarksAndSpencer/fonts';
import styled from 'styled-components';

export const StyledUnorderedList = styled.ul`
  padding: 0;
  margin-bottom: ${spacing(3)};
  margin-top: ${spacing(4)};

  ${mediaQuery.tabletPortrait`
    margin-bottom: ${spacing(6)};
    margin-top: ${spacing(6)};
  `}

  ${mediaQuery.tabletLandscape`
    margin-top: ${spacing(5)};
  `}
`;

export const ListItemWithMargins = styled.li`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: ${spacing(3)} 0;
  margin: 0;
  border-bottom: 1px solid ${colors.neutral04};

  ${fonts.paragraph};
  & {
    font-weight: ${regularFontWeight};
  }

  ${mediaQuery.tabletPortrait`
    flex-direction: row;
    padding: ${spacing(2)} 0;
  `}

  ${mediaQuery.tabletLandscape`
    padding: ${spacing(3)} 0;
    margin: 0;

    ${fonts.headingXSmall};
    & {
      font-weight: ${regularFontWeight};
    }
  `}
`;

export const ValueSpan = styled(RichText)`
  ${fonts.paragraph}

  ${mediaQuery.tabletLandscape`
    ${fonts.paragraphLarge}
  `}

  ${mediaQuery.tabletPortrait`
    text-align: right;
    max-width: 50%;
  `};
`;

export const LabelContainer = styled.div`
  ${mediaQuery.tabletPortrait`
    max-width: 50%;
  `}
`;

export const Label = styled.div`
  ${fonts.paragraph}

  ${mediaQuery.tabletLandscape`
    ${fonts.paragraphLarge};
  `}

  margin-bottom: ${spacing(1)};

  ${mediaQuery.tabletPortrait`
    :last-of-type {
      margin-bottom: 0;
    }
  `}
`;

export const LabelExplanation = styled(RichText)`
  ${fonts.paragraphSmall};

  ${mediaQuery.tabletPortrait`
    ${fonts.paragraph};
  `}

  margin-bottom: ${spacing(1)};

  ${mediaQuery.tabletPortrait`
    :last-of-type {
      margin-bottom: 0;
    }
  `}
`;
