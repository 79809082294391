import { Grid, GridItem } from '@rsa-digital/evo-shared-components/components/Grid';
import { graphql } from 'gatsby';
import React, { Dispatch, useReducer } from 'react';
import { useDispatch } from 'react-redux';
import QuoteDetailsSection from 'components/Confirmation/QuoteDetailsSection';
import FormFooter from 'components/FormFooter';
import Layout from 'components/Layout';
import { Link } from 'components/PolicyDocuments';
import PolicyLink from 'components/PolicyDocuments/PolicyLink';
import { unwrapSingleton } from 'helpers/csTypeProcessors';
import { useValidConfirmationOffers } from 'helpers/discountHelpers';
import { PageTitle, trackTextButtonClick } from 'helpers/eventTracking';
import { handleAndTrackLinkClick } from 'helpers/navigation';
import { usePageTracking } from 'helpers/pageTracking';
import { confirmationQuotePlaceholders } from 'helpers/placeholders/quotePlaceholders';
import {
  replacePlaceholdersPlainText,
  replacePlaceholdersRichText,
} from 'helpers/placeholders/replaceCsPlaceholders';
import { ERROR, ErrorAction, ErrorType } from 'state/error/actions';
import { useConfirmationQuote } from 'state/quote/confirmationQuote';
import { CsAsset, CsHero, CsIcon } from 'types/contentStack';
import {
  DocumentsDispatchingDetails,
  IntroMessage,
  StyledDiscountPanel,
  StyledGridItem,
} from './styles';

type ConfirmationProps = {
  data: {
    csPetConfirmationV2: {
      meta_title: string;
      hero: CsHero;
      intro_message: string;
      next_button_text: string;
      next_button_url: string;
      documents_dispatching_details: string;
      policy_wording_link: Link;
      bullet_icon: [CsIcon] | [];
      offer_promobox: {
        icon: [CsIcon];
        heading: string;
        body: string;
        offer_image: CsAsset;
      };
    };
  };
};

export const query = graphql`
  query {
    csPetConfirmationV2 {
      meta_title
      hero {
        heading
        subheading
      }
      intro_message
      next_button_text
      next_button_url
      documents_dispatching_details
      policy_wording_link {
        url
        text
        screenreader_text
        open_in_new_tab
      }
      bullet_icon {
        icon_code
      }
    }
  }
`;

const Confirmation: React.FC<ConfirmationProps> = ({
  data: {
    csPetConfirmationV2: {
      hero,
      intro_message,
      next_button_text,
      next_button_url,
      meta_title,
      documents_dispatching_details,
      policy_wording_link,
      bullet_icon,
    },
  },
}) => {
  const [quote] = useConfirmationQuote();
  usePageTracking(meta_title, !!quote, quote);

  const dispatchError: Dispatch<ErrorAction> = useDispatch();
  const [errorDispatched, setErrorDispatched] = useReducer(() => true, false);
  const offers = useValidConfirmationOffers();

  if (!quote) {
    if (!errorDispatched) {
      dispatchError({
        type: ERROR,
        errorType: ErrorType.CONFIRMATION_SESSION_EXPIRED,
      });
      setErrorDispatched();
    }

    return null;
  }

  const richTextPlaceholderReplacer = replacePlaceholdersRichText(
    confirmationQuotePlaceholders,
    quote
  );
  const plainTextPlaceholderReplacer = replacePlaceholdersPlainText(
    confirmationQuotePlaceholders,
    quote,
    true
  );

  const icon = unwrapSingleton(bullet_icon)?.icon_code;

  return (
    <Layout
      heading={plainTextPlaceholderReplacer(hero.heading)}
      subheading={hero.subheading}
      metaTitle={meta_title}
      pageTitle={PageTitle.Confirmation}>
      <Grid alignLeft>
        <StyledGridItem desktop={8} tabletLandscape={8}>
          <IntroMessage html={richTextPlaceholderReplacer(intro_message)} />
          <QuoteDetailsSection quote={quote} />
          <DocumentsDispatchingDetails
            html={documents_dispatching_details}
            pageTitle={PageTitle.Confirmation}
          />
          <PolicyLink
            icon={icon}
            url={policy_wording_link.url}
            text={policy_wording_link.text}
            screenreaderText={policy_wording_link.screenreader_text ?? undefined}
            openInNewTab={policy_wording_link.open_in_new_tab}
            onClick={() =>
              trackTextButtonClick(
                PageTitle.Confirmation,
                'Policy wording document button'
              )
            }
          />
          {offers &&
            offers.map((offer) => (
              <StyledDiscountPanel
                data-cy="discountPanel"
                offerPanel={offer}
                pageTitle={PageTitle.Confirmation}
              />
            ))}
        </StyledGridItem>
        <GridItem desktop={8} tabletLandscape={8}>
          <FormFooter
            moveNextButton={{
              text: next_button_text,
              href: next_button_url,
              onClick: (e) => {
                handleAndTrackLinkClick(
                  next_button_url,
                  PageTitle.Confirmation,
                  'Go to homepage',
                  e
                );
              },
            }}
            pageTitle={PageTitle.Confirmation}
          />
        </GridItem>
      </Grid>
    </Layout>
  );
};

export default Confirmation;
