import Icon from '@rsa-digital/evo-shared-components/components/Icon';
import componentProps, {
  ComponentProps,
} from '@rsa-digital/evo-shared-components/helpers/componentProps';
import React, { MouseEventHandler, PropsWithChildren } from 'react';
import { ButtonWrapper, IconWrapper, StyledButtonLink } from './styles';

export type LinkWithIconProps = {
  id?: string;
  onClick: MouseEventHandler;
  iconCode?: string;
} & ComponentProps;

const LinkWithIcon: React.FC<PropsWithChildren<LinkWithIconProps>> = ({
  id,
  children,
  onClick,
  iconCode,
  ...props
}) => (
  <StyledButtonLink {...componentProps(props)} id={id} onClick={onClick}>
    <ButtonWrapper>
      {iconCode && (
        <IconWrapper>
          <Icon name={iconCode} size="small" />
        </IconWrapper>
      )}
      {children}
    </ButtonWrapper>
  </StyledButtonLink>
);

export default LinkWithIcon;
