import FormFooter from '@rsa-digital/evo-shared-components/components/Form/FormFooter';
import colors from '@rsa-digital/evo-shared-components/helpers/colors';
import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled from 'styled-components';
import LinkWithIcon from 'components/LinkWithIcon';

export const FormFooterWithTopMargin = styled(FormFooter)`
  margin-top: ${spacing(4)};
`;

export const StyledLinkWithIcon = styled(LinkWithIcon)`
  margin: 0;
  border: 2px solid ${colors.core01};
  padding: ${spacing(1)} ${spacing(3)};
  transition: all 0.5s ease-out;
  &&& {
    text-decoration: none;
  }

  :not(:last-child) {
    margin-bottom: ${spacing(3)};
  }

  ${mediaQuery.tabletPortrait`
    margin-bottom: ${spacing(3)};
    margin-top: ${spacing(1.5)};
    :not(:last-child) {
      margin-right: ${spacing(6)};
    }
    & {
      ${fonts.paragraphLarge}
    }
  `}

  ${mediaQuery.tabletLandscape`
    :not(:last-child) {
      margin-right: ${spacing(5)};
    }
  `}
  &:hover {
    box-shadow: ${colors.core01} 0px 0px 0px 2px inset;
  }
`;
