import { VoluntaryExcessAmount } from './businessConstants';
import { formatStringInPoundsWithSign } from './poundsHelpers';

export const getTotalAge9ExcessAmount = (excessAmount: VoluntaryExcessAmount): string => {
  switch (excessAmount) {
    case VoluntaryExcessAmount.Excess_50:
      return formatStringInPoundsWithSign(VoluntaryExcessAmount.Excess_150);
    case VoluntaryExcessAmount.Excess_100:
      return formatStringInPoundsWithSign(VoluntaryExcessAmount.Excess_200);
    case VoluntaryExcessAmount.Excess_125:
      return formatStringInPoundsWithSign(VoluntaryExcessAmount.Excess_225);
    case VoluntaryExcessAmount.Excess_150:
      return formatStringInPoundsWithSign(VoluntaryExcessAmount.Excess_250);
    default:
      return '';
  }
};
