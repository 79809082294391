import { ButtonLink } from '@rsa-digital/evo-shared-components/components/Link';
import colors from '@rsa-digital/evo-shared-components/helpers/colors';
import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled from 'styled-components';

export const StyledButtonLink = styled(ButtonLink)`
  ${fonts.paragraph}
  & {
    color: ${colors.core01};
    font-weight: normal;
  }

  text-decoration: underline;

  &:hover,
  &:focus {
    text-decoration: none;
  }
`;

export const IconWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto ${spacing(1)} auto 0;
`;

export const ButtonWrapper = styled.div`
  display: flex;
`;
