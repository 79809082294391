import React, { Fragment } from 'react';
import { ConfirmationQuote } from 'state/quote/confirmationQuote';
import {
  Label,
  LabelContainer,
  LabelExplanation,
  ListItemWithMargins,
  StyledUnorderedList,
  ValueSpan,
} from './styles';
import { useConfirmationDetails } from './useConfirmationDetails';

type QuoteDetailsSectionProps = {
  quote: ConfirmationQuote;
};

const QuoteDetailsSection: React.FC<QuoteDetailsSectionProps> = ({ quote }) => {
  const isAnnual = quote.quoteOptions.isAnnualPayment;
  const details = useConfirmationDetails(quote, isAnnual);

  return (
    <StyledUnorderedList>
      {details.map((detail) => (
        <Fragment key={detail.label}>
          <ListItemWithMargins>
            <LabelContainer>
              <Label>{detail.label}</Label>
              {detail.explanationText && (
                <LabelExplanation html={detail.explanationText} />
              )}
            </LabelContainer>
            <ValueSpan html={detail.value} />
          </ListItemWithMargins>
        </Fragment>
      ))}
    </StyledUnorderedList>
  );
};

export default QuoteDetailsSection;
