import { QuoteResponsePetInfo } from 'apiHelpers/quote/quoteResponse';
import { trimEnd } from 'lodash';
import { formatStringInPounds } from 'helpers/poundsHelpers';
import { CsPlaceholders } from './replaceCsPlaceholders';
import { getTotalAge9ExcessAmount } from '../excessAmountHelper';

export const petPlaceholders: CsPlaceholders<QuoteResponsePetInfo> = {
  petName: {
    getSubstitutionText: (pet) => trimEnd(pet.petName),
    isPii: false,
  },
  excessAmount: {
    getSubstitutionText: (pet) => formatStringInPounds(pet.voluntaryExcessAmount),
    isPii: false,
  },
  totalExcessAmount: {
    getSubstitutionText: (pet) => getTotalAge9ExcessAmount(pet.voluntaryExcessAmount),
    isPii: false,
  },
};
